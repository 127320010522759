import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import RouterPage from "./Pages/Router";

function App() {
  return (                                  
    <div className="App">      
      <RouterPage/>
    </div>
  );    
} 
export default App;  
                                     